import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	header: {
		fontWeight: 700,
		color: theme.workwaveBlue,
		fontSize: '2.0243rem',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.8219rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5625rem',
		},
	},
	subHeader: {
		fontWeight: 400,
		color: theme.workwaveBlue,
		fontSize: '1.25rem',
	},
	printSubHeader: {
		fontWeight: 400,
		color: '#4B5B69',
		fontSize: '1.25rem',
	},
}));

export const FeatureHeader = ({
	print,
	bodyHeader,
	bodySubHeader,
	routing,
}) => {
	const classes = useStyles();
	return (
		<>
			{!!print ? (
				<>
					<Typography variant='h2' className={classes.header}>
						{bodyHeader}
					</Typography>
					<Typography variant='h5' className={classes.printSubHeader}>
						{bodySubHeader}
					</Typography>
				</>
			) : (
				<>
					<Typography variant='h5' className={classes.subHeader}>
						{bodySubHeader}
					</Typography>
					<Typography
						variant='h2'
						className={classes.header}
						style={{
							color: routing && 'white',
						}}>
						{bodyHeader}
					</Typography>
				</>
			)}
		</>
	);
};
