import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import loadable from '@loadable/component';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import FeatureThumbnailContext from '../../../context/FeatureThumbnailContext';

const Video = loadable(() => import('../../Home/Hero/Video'));

const useStyles = makeStyles((theme) => ({
	playerWrapper: {
		position: 'relative',
		paddingTop: '56.25%',
		minHeight: '100%',
		minWidth: '100%',
		border: '1px solid #d8dee0',
	},
	img: {
		maxWidth: '580px',
		maxHeight: '550px',
		[theme.breakpoints.down('md')]: {
			maxWidth: '504px',
		},
	},
	'@keyframes pulseBorder': {
		'0%': {
			transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1)',
			opacity: '1',
		},
		'100%': {
			transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1.5)',
			opacity: '0',
		},
	},
	playButton: {
		height: '100px',
		width: '100px',
		background: '#fff',
		color: '#002d5c',
		fontSize: '1.5rem',
		borderRadius: '50%',
		opacity: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
		cursor: 'pointer',
		zIndex: 5,

		'&::before': {
			content: `''`,
			position: 'absolute',
			zIndex: '-1',
			left: '50%',
			top: '50%',
			transform: 'translateX(-50%) translateY(-50%)',
			display: 'block',
			width: '100px',
			height: '100px',
			background: '#fff',
			borderRadius: '50%',
		},

		'&:hover': {
			'& $playButtonEffect': { transform: 'scale(1.1)' },
			'&::before': {
				animation: '$pulseBorder 1500ms ease-out infinite',
			},
		},
	},
	playWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '500px',
		position: 'absolute',
		[theme.breakpoints.down(465)]: {
			height: '325px',
		},
		[theme.breakpoints.down(380)]: {
			height: '300px',
		},
	},
}));

export const FeatureContent = ({ image, thumbnailVideoUrl, videoVariant }) => {
	const { handleSelectThumbnail } = useContext(FeatureThumbnailContext);

	const classes = useStyles();
	return (
		<Grid item xs={12} md={6} container justifyContent='center'>
			{image ? (
				<>
					<GatsbyImage
						image={image.featureImage?.asset?.gatsbyImageData}
						alt='chart'
						className={classes.img}
						imgStyle={{ objectFit: 'contain', transition: '.5s' }}
					/>
					{thumbnailVideoUrl ? (
						<div className={classes.playWrapper}>
							<div
								className={classes.playButton}
								onClick={(e) => handleSelectThumbnail(e, thumbnailVideoUrl)}>
								<FontAwesomeIcon icon={['fas', 'play']} size='lg' />
							</div>
						</div>
					) : null}
				</>
			) : // )
			videoVariant ? (
				<div className={classes.playerWrapper}>
					<Video
						url={videoVariant}
						height='100%'
						width='100%'
						controls={true}
					/>
				</div>
			) : null}
		</Grid>
	);
};
